import React from 'react';
import { EmailLink } from "../bidding/common/EmailLink";
import { PhoneNumberLink } from "../bidding/common/PhoneNumberLink";

export const LearnMore = () =>
    <>
        <hr />
        <div className="container-learn-more">
            <h1 className="learn-more">Want to learn more?</h1>
            <div className="item-info-blue">
                <span className="learn-more-text">
                    Contact us via <EmailLink className="learn-more-email" email={process.env.REACT_APP_LEARN_MORE_EMAIL} /> or call now:
                </span>
                <PhoneNumberLink phone="1.800.862.1684" className="btn btn-main" icon />
            </div>
        </div>
    </>
