import { useEffect } from 'react';
import { accountService } from '../../services';

export function Logout() {
    useEffect(() => {
        accountService.apiLogout();
        accountService.logout();
    }, []);

    return null;
}
