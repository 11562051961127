import { Redirect } from 'react-router';
import {  useSelector } from "react-redux";
import { AppState } from '../../../types/state/AppState';
import { VerifyIdentityMethod } from './VerifyIdentityMethod';
import { VerifyIdentityMethodType } from '../../../types/account/VerifyIdentityMethodType';
import { VerifyIdentityState } from '../../../types/state/VerifyIdentityState';
import { verifyIdentityActions } from '../../../actions/verify.identity.actions';
import { routes } from '../../../constants/routes';
import { Preloader, OnHoverTooltip } from '../../common';
import { useAppDispatch } from '../../../effects/useAppDispatch';

export function IdentityCodeRequest() {
    const dispatch = useAppDispatch();
    const {
        isVerificationRequired,
        isVerificationCodeRequesting,
        selectedMethod,
        emailCode,
        phoneCode
    } = useSelector<AppState, VerifyIdentityState>(state => state.verifyIdentity);

    if (!isVerificationRequired) {
        return <Redirect to={routes.root} />
    }

    return (
        <div className="tfa-container">
            <div className="tfa-verify">
                <h2>Verify your identity</h2>
                <Preloader inProgress={isVerificationCodeRequesting}>
                    <p>How would you like to get your security code?</p>
                    <div className="form-row">
                        <VerifyIdentityMethod
                            title="Email"
                            text={emailCode}
                            enabled={true}
                            selected={selectedMethod === VerifyIdentityMethodType.email}
                            onClick={() => dispatch(verifyIdentityActions.changeSelectedMethod(VerifyIdentityMethodType.email))}
                        />
                    </div>
                    <div className="form-row">
                        <VerifyIdentityMethod
                            title="SMS"
                            text={phoneCode}
                            enabled={!!phoneCode}
                            selected={selectedMethod === VerifyIdentityMethodType.sms}
                            onClick={() => dispatch(verifyIdentityActions.changeSelectedMethod(VerifyIdentityMethodType.sms))}
                        >
                            {
                                !phoneCode &&
                                <div className="btn-enable">
                                    <OnHoverTooltip overlay="Once you are signed in you can enable receiving a text
                                        message containing a verification code. Please go to the user profile and select the
                                        'Two-factor Authentication' section in the left side panel.">
                                        <button className="btn-link">Want to enable?</button>
                                    </OnHoverTooltip>
                                </div>
                            }
                        </VerifyIdentityMethod>
                    </div>
                    <button
                        className="btn btn-main btn-full-width"
                        onClick={() => dispatch(verifyIdentityActions.requestVerificationCode())}
                    >
                        get code
                    </button>
                </Preloader>
            </div>
        </div>
    );
}
