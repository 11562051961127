import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Form, ValidationMessage } from '../forms';
import { constants, routes } from '../../constants';
import '../../styles/main.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RequestState } from '../../constants/request-state';
import { Preloader } from '../common';
import { signupActions } from '../../actions/signup.actions';

export function Signup() {
    const dispatch = useDispatch();
    const rsPostSignupForm = useSelector(state => state.signup.requestState);
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [company, setCompany] = useState('');
    const [companyType, setCompanyType] = useState(0);

    const handleSubmit = () => {
        const form = { fullName, email, phoneNumber, company, companyType };
        dispatch(signupActions.submit(form));
    }

    const readyToSubmit = fullName && email && phoneNumber && company && companyType !== 0;
    const isPosting = rsPostSignupForm === RequestState.request;

    return (
        <div className="container sign-up">
            <Preloader inProgress={isPosting}>
                <Form onSubmit={handleSubmit}>
                    <div className="sign-up-cnt">
                        <h2>Sign Up</h2>

                        <div className="form-row">
                            <label className="form-label" htmlFor="name">
                                Full Name <span className="text-red">*</span>
                            </label>
                            <div className="form-control-wrapper">
                                <input
                                    autoFocus
                                    className="form-control"
                                    id="fullName"
                                    name="fullName"
                                    type="text"
                                    required
                                    pattern={constants.name.source}
                                    maxLength="256"
                                    placeholder="First name and last name"
                                    value={fullName}
                                    onChange={e => setFullName(e.target.value)}
                                />
                                <ValidationMessage
                                    for="fullName"
                                    defaultMessage="Full name is invalid"
                                />
                            </div>
                        </div>

                        <div className="form-row">
                            <label className="form-label" htmlFor="email">Email <span className="text-red">*</span></label>
                            <div className="form-control-wrapper">
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    className="form-control"
                                    required
                                    placeholder="Email"
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                />
                                <ValidationMessage
                                    for="email"
                                    requiredMessage="Email is required"
                                    defaultMessage="Invalid email address"
                                />
                            </div>
                        </div>

                        <div className="form-row">
                            <label className="form-label" htmlFor="phoneNumber">Phone Number <span className="text-red">*</span></label>
                            <div className="form-control-wrapper">
                                <input
                                    type="tel"
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    className="form-control"
                                    required
                                    pattern={constants.nonWhitespace.source}
                                    placeholder="Phone Number"
                                    maxLength="20"
                                    value={phoneNumber}
                                    onChange={e => setPhoneNumber(e.target.value)}
                                />
                                <ValidationMessage
                                    for="phoneNumber"
                                    requiredMessage="Phone Number is required"
                                    defaultMessage="Invalid Phone Number"
                                />
                            </div>
                        </div>

                        <div className="form-row">
                            <label className="form-label" htmlFor="company">
                                Company <span className="text-red">*</span>
                            </label>
                            <div className="form-control-wrapper">
                                <input
                                    className="form-control"
                                    id="company"
                                    name="company"
                                    type="text"
                                    required
                                    pattern={constants.nonWhitespace.source}
                                    maxLength="256"
                                    placeholder="Company"
                                    value={company}
                                    onChange={e => setCompany(e.target.value)}
                                />
                                <ValidationMessage
                                    for="company"
                                    defaultMessage="Company is invalid"
                                />
                            </div>
                        </div>

                        <div className="form-row double-margin">
                            <label className="form-label">
                                Company Type <span className="text-red">*</span>
                            </label>
                            <div className="control-radio-square">
                                <input
                                    type="radio"
                                    id="company-broker"
                                    name="companyType"
                                    value="1"
                                    required
                                    checked={companyType === 1}
                                    onChange={() => setCompanyType(1)}
                                />
                                <label className="control-label" htmlFor="company-broker">Broker-Dealer</label>
                            </div>
                            <div className="control-radio-square">
                                <input
                                    type="radio"
                                    id="company-manager"
                                    name="companyType"
                                    value="2"
                                    checked={companyType === 2}
                                    onChange={() => setCompanyType(2)}
                                />
                                <label className="control-label" htmlFor="company-manager">Asset Manager</label>
                            </div>
                            <ValidationMessage for="companyType"/>
                        </div>

                        <div className="form-row margin-b-0">
                            <div className="text-sm text-warm-grey">By clicking Sign Up, you agree to <Link to={routes.termsAndConditions}>Terms &amp; Conditions</Link> and <Link to={routes.privacyPolicy}>Privacy Policy</Link>.</div>
                        </div>

                        <div className="form-row">
                            <button
                                className="btn btn-main btn-margin"
                                type="submit"
                                disabled={!readyToSubmit || isPosting}
                            >
                                Sign Up
                            </button>
                            <div className="divider"><span>Have an account?</span></div>
                            <Link to={routes.login} className="btn btn-ghost btn-full-width">sign in</Link>
                        </div>
                    </div>
                </Form>
            </Preloader>
        </div>
    );
}

