import { useHistory } from 'react-router';
import cn from "classnames";
import { appConfig } from '../../app-config';
import IconSVG from '../../styles/svg-icons';
import { user } from '../../user/user';
import { EmailLink } from '../bidding/common/EmailLink';
import { routes } from '../../constants/routes';
import { SubscriptionsTab } from '../profile/tabs/subscriptions/SubscriptionsTab';
import { logger } from '../../logging/logger';
import { billingService } from '../../services/billing.service';

interface SubscribeLinkProps {
    text?: string;
    btnClassName?: string;
}

export function SubscribeLink({ text, btnClassName = "btn-link secondary text-regular" }: SubscribeLinkProps) {
    const history = useHistory();

    const handleClick = () => {
        history.push(routes.subscriptionsTab(SubscriptionsTab.plans));
        billingService
            .notifySales()
            .catch(e => logger.exception(e, "Failed to send sales notification"));
    }

    return (
        <button
            className={btnClassName}
            onClick={handleClick}
        >
            {text ?? "Subscribe to the Pro plan"}
        </button>
    );
}

interface Props {
    className?: string,
    inline?: boolean,
    withBackground?: boolean,
    text?: React.ReactNode;
}
export function BlockedFeatureContent({ className, inline, withBackground, text }: Props) {
    const classNames = cn(
        "restricted-placeholder",
        {
            "restricted-placeholder-box": !inline || withBackground,
            "restricted-placeholder-bg": withBackground,
            "restricted-placeholder-inline": inline,
        },
        className);

    return (
        <div className={classNames}>
            <div className="required-feature">
                <IconSVG name="subscribe-lock" width="24" height="24" />
                <p>
                    {Boolean(text) ? text : <><SubscribeLink /> to unlock feature details.</>}
                </p>
            </div>
        </div>
    );
}

export function BlockedFeatureTooltipText() {
    return (
        <p>
            Limited features on your {user.current()?.subscription} plan. Upgrade for full access. Contact <EmailLink email={appConfig.salesEmailSubscription} /> or call {appConfig.salesPhone}.
        </p>
    );
}
