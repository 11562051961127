import React, { Component } from 'react';
import { Popup } from '../controls';
import classNames from 'classnames';

export default class Confirm extends Component {
    handleClose = () => {
        if (!this.props.disabled) {
            this.props.onCancel();
        }
    }

    render = () => {
        const {
            title,
            text,
            TextComponent,
            onConfirm,
            confirmButtonText = "Confirm",
            cancelButtonText = "Cancel",
            confirmButtonDangerStyle = false,
            disabled = false,
            renderInBody
        } = this.props;

        if (!text && !TextComponent) {
            return null;
        }

        return (
            <Popup renderInBody={renderInBody} modalClass="modal-confirmation" title={title || 'Confirmation'} onClose={this.handleClose}>
                <div className="modal-body">
                    <p className="status-message alert">
                        <i className="icon icon-warning" />
                        <span className="status-message-cnt">
                            {!!TextComponent && <TextComponent />}
                            {text}
                        </span>
                    </p>
                </div>
                <div className="modal-footer">
                    <button className="btn btn-ghost" disabled={disabled} onClick={this.handleClose}>{cancelButtonText}</button>
                    <button
                        className={classNames('btn', 'btn-main', { 'btn-danger': confirmButtonDangerStyle })}
                        disabled={disabled}
                        onClick={onConfirm.bind(this)}
                    >
                        {confirmButtonText}
                    </button>
                </div>
            </Popup>
        );
    }
}
