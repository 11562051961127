import { user } from '../../user/user';
import { SubscriptionFeature } from '../../types/billing/SubscriptionFeature';
import { BlockedFeatureContent } from './BlockedFeatureText';
import { BlockedFeatureContext } from './BlockedFeatureContext';

interface Props {
    feature?: SubscriptionFeature;
    features?: SubscriptionFeature[];
    requireAllFeatures?: boolean;
    overrideRequiredFeatures?: boolean;
    children: React.ReactNode;
    inline?: boolean,
    withBackground?: boolean,
    className?: string,
    text?: React.ReactNode;
    renderBlockedContent?: () => React.ReactNode;
}

export function RequiredFeature({
    feature,
    features,
    requireAllFeatures,
    overrideRequiredFeatures,
    children,
    text,
    inline,
    withBackground,
    className,
    renderBlockedContent }: Props) {

    if (overrideRequiredFeatures) return <>{children}</>;

    let allFeatures = features || [];
    if (feature) {
        allFeatures.push(feature);
    }

    if(!allFeatures.length) return <>{children}</>;

    const hasFeatures = requireAllFeatures
        ? user.hasAllFeatures(...allFeatures)
        : user.hasFeatures(...allFeatures);

    if (hasFeatures) return <>{children}</>;

    return (
        <BlockedFeatureContext.Provider value={true}>
            {renderBlockedContent == null ?
                <BlockedFeatureContent
                    inline={inline}
                    withBackground={withBackground}
                    className={className}
                    text={text}
                /> : renderBlockedContent()}
        </BlockedFeatureContext.Provider>
    );
}
