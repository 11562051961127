import { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { accountActions } from '../../actions';
import { Form, ValidationMessage, PasswordStrengthValidator } from '../forms';
import { errorMessages, routes } from '../../constants';
import ImageSVG from '../../styles/svg';
import { user } from '../../user';
import { Preloader } from '../common';
import { history } from '../../history';

class SetUpPassword extends Component {
    state = {
        newPassword: '',
        confirmPassword: '',
        isValid: true,
        isLengthValid: false,
        isLetterValid: false,
        isSpecCharsValid: false,
        isNumericValid: false
    }

    componentDidMount = () => {
        const { location, dispatch } = this.props;

        if (user.isAuthenticated()) {
            return  history.replace(routes.root);
        }

        const isFinishedState = location && location.state && location.state.isFinished;
        const isExpiredState = location && location.state && location.state.isExpired;
        if ((this.isFinished() && !isFinishedState) || (this.isExpired() && !isExpiredState)) {
            return history.replace(routes.login);
        }

        const { email } = queryString.parse(location.search);
        const { resetToken } = queryString.parse(location.search, { decode: false });

        dispatch(accountActions.setUpPasswordInit(email, resetToken));
    }

    componentWillUnmount = () => this.props.dispatch(accountActions.setUpPasswordReset());

    isFinished = () => {
        const { match } = this.props;
        return match && match.params && match.params.result === 'finished';
    }

    isExpired = () => {
        const { match } = this.props;
        return match && match.params && match.params.result === 'expired';
    }

    render = () => {
        const { error, tokenVerified } = this.props;

        if (this.isFinished()) {
            return (
                <div className="container-main">
                    <div className="content-account content-account-setup-pass-done">
                        <h2>Password Changed</h2>
                        <ImageSVG name="resetPassword" width={326} height={208} />
                        <p>
                            Your password has been changed.
                        </p>
                        <p>
                            Please <Link to={routes.login}>click here to sign in</Link>.
                        </p>
                    </div>
                </div>
            );
        }

        if (this.isExpired()) {
            return (
                <div className="container-flex">
                    <div className="content-account content-account-error content-login">
                        <div className="title">
                            <h2 className="title-text">Your link has expired</h2>
                        </div>

                        <ImageSVG name="linkExpired" width={326} height={208} />

                        <p className="reset-pass-text">Sorry, the link you used has expired.</p>
                        <p className="reset-pass-text">Please try again using the most recent reset password email in your inbox, or <Link to={routes.forgotPassword}>request a new link</Link>.</p>
                    </div>
                </div>
            );
        }

        if (tokenVerified !== true) {
            return <Preloader inProgress={true} />;
        }

        const { errorMessage } = error;

        return (
            <div className="container-main">
                <div className="content-account content-account-setup-pass">
                    <Form onSubmit={this.handleSubmit} onCustomValidate={this.handleCustomValidate}>
                        <h2>Setup Password</h2>
                        {/*<p>{this.state.email}</p>*/}
                        {
                            errorMessage &&
                            <div className={errorMessage ? 'status-message error' : 'status-message display-none'}>
                                <i className="icon icon-error" />
                                <span className="status-message-cnt">{errorMessage}</span>
                            </div>
                        }

                        <div className="form-row">
                            <label className="form-label" htmlFor="passUser">New Password <span className="text-red">*</span></label>
                            <div className="form-control-wrapper">
                                <input
                                    id="passUser"
                                    className="form-control"
                                    type="password"
                                    name="newPassword"
                                    autoComplete="new-password"
                                    onChange={this.handleChange}
                                    required
                                    placeholder="Type your new password"
                                />
                                <ValidationMessage
                                    for="newPassword"
                                    trigger={this.state.isValid}
                                    requiredMessage={errorMessages.passwordRequired}
                                />
                            </div>
                        </div>

                        <div className="form-row">
                            <label className="form-label" htmlFor="confirmPassUser">Confirm Password <span className="text-red">*</span></label>
                            <div className="form-control-wrapper">
                                <input
                                    id="confirmPassUser"
                                    className="form-control"
                                    type="password"
                                    name="confirmPassword"
                                    autoComplete="confirm-password"
                                    onChange={this.handleChange}
                                    required
                                    placeholder="Confirm your new password"
                                />
                                <ValidationMessage
                                    for="confirmPassword"
                                    requiredMessage={errorMessages.confirmPasswordRequired}
                                    customMessage={errorMessages.passwordsNotMatch}
                                    onCustomValidate={this.handlePasswordsMatch}
                                />
                            </div>
                        </div>

                        <div className="password-requirements">
                            <p>Your password must contain:</p>

                            <ul>
                                <li>
                                    <PasswordStrengthValidator
                                        password={this.state.newPassword}
                                        regexp="(?=^.{8,40}$)"
                                        onValidate={(isLengthValid) => this.setState({ isLengthValid })}
                                        message="8-40 characters in length"
                                    />
                                </li>
                                <li>
                                    <PasswordStrengthValidator
                                        password={this.state.newPassword}
                                        regexp="^(?=.*[a-z])(?=.*[A-Z])"
                                        onValidate={(isLetterValid) => this.setState({ isLetterValid })}
                                        message="at least 1 lowercase and 1 uppercase letter"
                                    />
                                </li>
                                <li>
                                    <PasswordStrengthValidator
                                        password={this.state.newPassword}
                                        regexp={'^(?=.*[ !"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~\\\\])'}
                                        onValidate={(isSpecCharsValid) => this.setState({ isSpecCharsValid })}
                                        message="at least 1 special character (!@#$%^&*)"
                                    />
                                </li>
                                <li>
                                    <PasswordStrengthValidator
                                        password={this.state.newPassword}
                                        regexp="^(?=.*\d)"
                                        onValidate={(isNumericValid) => this.setState({ isNumericValid })}
                                        message="at least 1 numeric character (0-9)"
                                    />
                                </li>
                            </ul>
                        </div>
                        <div className="form-row margin-b-0">
                            <div className="text-sm text-warm-grey">
                                By registering to KopenTech's products, you agree to our <Link to={routes.termsAndConditions}>Terms &amp; Conditions</Link> and <Link to={routes.privacyPolicy}>Privacy Policy</Link>.
                            </div>
                        </div>
                        <div className="authorization-actions">
                            <button type="submit" className="btn btn-main btn-setup-pass">Update Password</button>
                        </div>
                    </Form>
                </div>
            </div>
        );
    }

    handlePasswordsMatch = confirmPassword =>
        confirmPassword.length > 0 && confirmPassword === this.state.newPassword;

    handleChange = (e) => {
        if (this.props.error) {
            this.props.error.errorMessage = null;
        }

        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleCustomValidate = () => this.handlePasswordsMatch(this.state.confirmPassword);

    handleSubmit = isValid => {
        this.setState({ isValid });

        const { isLengthValid, isLetterValid, isSpecCharsValid, isNumericValid } = this.state;

        if (isValid && isLengthValid && isLetterValid && isSpecCharsValid && isNumericValid) {
            const { newPassword, confirmPassword } = this.state;

            this.props.dispatch(accountActions.setUpPassword(newPassword, confirmPassword));
        } else {
            this.props.dispatch(accountActions.passwordValidationFailure(errorMessages.invalidNewPassword));
        }
    }
}

const mapStateToProps = ({ authentication }) => ({
    success: authentication.success,
    error: authentication.error || {},
    tokenVerified: authentication.tokenVerified,
});

const connectedSetUpPassword = connect(mapStateToProps)(SetUpPassword);
export { connectedSetUpPassword as SetUpPassword };
