import * as React from 'react';
import { user } from '../../user/user';
import { SubscriptionFeature } from '../../types/billing/SubscriptionFeature';
import { BlockedFeatureContext } from './BlockedFeatureContext';
import { OnHoverTooltip } from '../common/OnHoverTooltip';
import { TooltipPlacement } from '../common/Tooltip';
import classNames from 'classnames';
import { BlockedFeatureTooltipText } from './BlockedFeatureText';

export type TRenderChildren = (blocked: boolean) => React.ReactNode;

export interface ActionBlockerProps {
    feature?: SubscriptionFeature;
    features?: SubscriptionFeature[];
    requireAllFeatures?: boolean;
    overrideRequiredFeatures?: boolean;
    children: React.ReactNode | TRenderChildren;
    className?: string;
    placement?: string | TooltipPlacement;
    tooltipContent?: React.ReactNode;
    noTooltip?: boolean;
}

export function ActionBlocker({
    feature,
    features = [],
    requireAllFeatures,
    children,
    overrideRequiredFeatures,
    className,
    placement,
    tooltipContent,
    noTooltip = false,
}: ActionBlockerProps) {
    const renderChildren = (blocked: boolean) => {
        if (typeof children === 'function') {
            return <>{children(blocked)}</>;
        }

        return <>{children}</>;
    };

    if (overrideRequiredFeatures) return renderChildren(false);

    let allFeatures = features || [];
    if (feature) {
        allFeatures.push(feature);
    }

    if (!allFeatures.length) return renderChildren(false);

    const hasFeatures = requireAllFeatures
        ? user.hasAllFeatures(...allFeatures)
        : user.hasFeatures(...allFeatures);

    if (hasFeatures) return renderChildren(false);

    if (noTooltip) {
        return (
            <BlockedFeatureContext.Provider value={true}>{renderChildren(true)}</BlockedFeatureContext.Provider>
        );
    }

    return (
        <div className={classNames('limit-blocker position-relative', className)}>
            <OnHoverTooltip
                placement={placement}
                overlay={tooltipContent ?? <BlockedFeatureTooltipText />}
                overlayClassName="trial-tooltip"
            >
                <BlockedFeatureContext.Provider value={true}>{renderChildren(true)}</BlockedFeatureContext.Provider>
            </OnHoverTooltip>
        </div>
    );
}
