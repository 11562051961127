import IconSVG from '../../styles/svg-icons';
import { ActionBlocker, ActionBlockerProps } from './ActionBlocker';

export const ValueBlocker = ({ children, ...restProps }: ActionBlockerProps) => {
    return (
        <ActionBlocker {...restProps}>
            {blocked => blocked
                ? <IconSVG width="16" height="16" name="subscribe-lock-grey" />
                : <>{children}</>
            }
        </ActionBlocker>
    );
};
