import { compact } from 'lodash';
import { user } from '../../user';
import { BlockedFeatureContext } from './BlockedFeatureContext';
import { BlockedPlatformAccessText } from './BlockedPlatformAccessText';
import { BlockedFeatureContent } from "./BlockedFeatureText";

interface RequiredRoleProps {
    requiredRole?: string;
    requiredRoles?: string[];
    children: React.ReactNode;
    renderBlockedContent?: () => React.ReactNode;
}

export function RequiredRole({
    requiredRoles = [],
    requiredRole,
    children,
    renderBlockedContent,
}: RequiredRoleProps) {
    const allRoles = compact([...requiredRoles, requiredRole]);

    if (user.hasRoles(...allRoles)) {
        return children as React.ReactElement<any>;
    }

    const renderDefaultBlocked = () => <BlockedFeatureContent text={<BlockedPlatformAccessText />} />;

    return (
        <BlockedFeatureContext.Provider value={true}>
            {renderBlockedContent == null ? renderDefaultBlocked() : renderBlockedContent()}
        </BlockedFeatureContext.Provider>
    );
}
