import * as React from 'react';
import { user } from '../../user/user';
import { SubscriptionFeature } from '../../types/billing/SubscriptionFeature';
import { compact } from 'lodash';

interface ShowForProps {
    roles?: string[];
    role?: string;
    singleRole?: boolean;
    feature?: SubscriptionFeature;
    features?: SubscriptionFeature[];
    overrideRequiredFeatures?: boolean;
    children?: React.ReactNode;
}

export const ShowFor = ({
    roles = [],
    role,
    singleRole = false,
    features = [],
    feature,
    overrideRequiredFeatures = false,
    children
}: ShowForProps) => {
    if (overrideRequiredFeatures) {
        return children as React.ReactElement<any>;
    }

    if (singleRole) {
        return ((role && user.hasSingleRole(role)) || (roles && roles.some(r => user.hasSingleRole(r))))
            ? children as React.ReactElement<any>
            : null;
    }

    const allRoles = compact([...roles, role]);
    const allFeatures = compact([...features, feature]);

    if (
        (!allRoles.length || user.hasRoles(...allRoles))
        && (!allFeatures.length || user.hasFeatures(...allFeatures))
    ) {
        return children as React.ReactElement<any>;
    }

    return null;
};
