import React, { Component } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import { Form, PasswordStrengthValidator, ValidationMessage } from '../forms';
import { accountActions } from '../../actions';
import { errorMessages } from '../../constants';
import { FormError } from '../controls';

class ChangePassword extends Component {
    state = {
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
        isLengthValid: false,
        isLetterValid: false,
        isSpecCharsValid: false,
        isNumericValid: false,
        errorMessageNewPassword: '',
    };

    componentWillUnmount = () => this.props.dispatch(accountActions.changePasswordReset());

    render = () => {
        const { errorMessageNewPassword } = this.state;
        const { error, onCancel } = this.props;
        const errorMessage = error ? error.errorMessage : null;

        return (
            <div className="tab-pane password container-flex">
                <Form onSubmit={this.handleSubmit} onCustomValidate={this.handleCustomValidate}>
                    <div className="sub-title">
                        <h1>Password</h1>
                        <div className="control">
                            <button className="btn btn-ghost" type="button" onClick={onCancel}>cancel</button>
                            <button className="btn btn-main" type="submit" disabled={!this.canSave()}>save</button>
                        </div>
                    </div>
                    <div className="content-section container-flex">
                        <div className="change-password-form">
                            <div className="form-item">
                                <label className="form-label">
                                    Current Password <span className="text-red">*</span>
                                </label>
                                <input
                                    className={cn('form-control', { 'is-invalid': !!errorMessage })}
                                    type="password"
                                    name="currentPassword"
                                    autoComplete="current-password"
                                    onChange={this.handleChange}
                                    required
                                    placeholder="Type your password"
                                />
                                <FormError message={errorMessage} />
                            </div>
                            <div className="form-item">
                                <label className="form-label">New Password <span className="text-red">*</span></label>
                                <input
                                    className={cn('form-control', { 'is-invalid': !!errorMessageNewPassword })}
                                    type="password"
                                    name="newPassword"
                                    autoComplete="new-password"
                                    onChange={this.handleChange}
                                    required
                                    placeholder="Type your new password"
                                />
                                {errorMessageNewPassword
                                    ? <FormError message={errorMessageNewPassword} />
                                    : (
                                        <ValidationMessage
                                            for="newPassword"
                                            requiredMessage={errorMessages.passwordRequired}
                                        />
                                    )}
                            </div>
                            <div className="form-item">
                                <label className="form-label">
                                    Confirm Password <span className="text-red">*</span>
                                </label>
                                <input
                                    className="form-control"
                                    type="password"
                                    name="confirmPassword"
                                    autoComplete="confirm-password"
                                    onChange={this.handleChange}
                                    required
                                    placeholder="Confirm your new password"
                                />
                                <ValidationMessage
                                    for="confirmPassword"
                                    requiredMessage={errorMessages.confirmPasswordRequired}
                                    customMessage={errorMessages.passwordsNotMatch}
                                    onCustomValidate={this.handlePasswordsMatch}
                                />
                            </div>
                            <div className="password-requirements">
                                <p>Your password must contain:</p>
                                <ul>
                                    <li>
                                        <PasswordStrengthValidator
                                            password={this.state.newPassword}
                                            regexp="(?=^.{8,40}$)"
                                            onValidate={(isLengthValid) => this.setState({ isLengthValid })}
                                            message="8-40 characters in length"
                                        />
                                    </li>
                                    <li>
                                        <PasswordStrengthValidator
                                            password={this.state.newPassword}
                                            regexp="^(?=.*[a-z])(?=.*[A-Z])"
                                            onValidate={(isLetterValid) => this.setState({ isLetterValid })}
                                            message="at least 1 lowercase and 1 uppercase letter"
                                        />
                                    </li>
                                    <li>
                                        <PasswordStrengthValidator
                                            password={this.state.newPassword}
                                            regexp={'^(?=.*[ !"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~\\\\])'}
                                            onValidate={(isSpecCharsValid) => this.setState({ isSpecCharsValid })}
                                            message="at least 1 special character (e.g. !@#$%^&*)"
                                        />
                                    </li>
                                    <li>
                                        <PasswordStrengthValidator
                                            password={this.state.newPassword}
                                            regexp="^(?=.*\d)"
                                            onValidate={(isNumericValid) => this.setState({ isNumericValid })}
                                            message="at least 1 numeric character (0-9)"
                                        />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
        );
    };

    handlePasswordsMatch = confirmPassword =>
        confirmPassword.length > 0 && confirmPassword === this.state.newPassword;

    handleChange = (e) => {
        if (this.props.error) {
            this.props.error.errorMessage = null;
        }

        const { name, value } = e.target;
        this.setState({ [name]: value, errorMessageNewPassword: '' });
    };

    handleCustomValidate = () => this.handlePasswordsMatch(this.state.confirmPassword);

    handleSubmit = isValid => {
        const { isLengthValid, isLetterValid, isSpecCharsValid, isNumericValid } = this.state;

        if (isValid && isLengthValid && isLetterValid && isSpecCharsValid && isNumericValid) {
            const { currentPassword, newPassword, confirmPassword } = this.state;
            this.props.dispatch(accountActions.changePassword(currentPassword, newPassword, confirmPassword));
        } else {
            this.setState({ errorMessageNewPassword: errorMessages.invalidNewPassword })
        }
    };

    canSave = () =>
        !this.props.error.errorMessage &&
        this.state.newPassword !== '' &&
        this.state.confirmPassword !== '' &&
        this.state.currentPassword !== '';
}

const mapStateToProps = ({ authentication }) => ({
    error: authentication.error || {}
});

const connectedChangePassword = connect(mapStateToProps)(ChangePassword);
export { connectedChangePassword as ChangePassword };
