import { Redirect, useLocation } from 'react-router';
import { routes } from '../../../constants';
import ImageSVG from '../../../styles/svg';

export function ArrangerClientSignupConfirm() {
    const state = useLocation<{ email?: string }>().state;

    if (!state?.email) {
        return <Redirect to={routes.login} />
    }

    return (
        <div className="container sign-up container-overflow">
            <div className="sign-up-thanks">
                <h1>Thank you!</h1>
                <ImageSVG name="resetPassword" width="326" height="195" />
                <p>We have sent an email with the link to setup your</p>
                <p> password to the following email: </p>
                <strong>{state.email}</strong>
            </div>
        </div>
    );
}
