import React from 'react';
import { Redirect, Route, Switch, useHistory } from "react-router";
import classNames from 'classnames'
import { routes } from '../../../constants/routes';
import { LearnMore } from '../LearnMore';
import { PlatformDisclaimer } from './tabs/PlatformDisclaimer';
import { PortfolioDisclaimer } from './tabs/PortfolioDisclaimer';
import { EvalDisclaimer } from './tabs/EvalDisclaimer';
import IconSVG from '../../../styles/svg-icons';
import { Disclosure } from './tabs/Disclosure';

enum DisclaimersTabs {
    platform = 'Platform',
    portfolio = 'Portfolio',
    eval = 'EVAL and K-Talk'
};

enum DisclosureTab {
    CusipGlobalServices = "CusipGlobalServices"
}

export function AuthenticatedDisclaimer() {
    const history = useHistory();

    const currentActiveTab = () => {
        switch (history.location.pathname) {
            case routes.disclaimersPlatform:
                return DisclaimersTabs.platform
            case routes.disclaimersPortfolio:
                return DisclaimersTabs.portfolio
            case routes.disclaimersEval:
                return DisclaimersTabs.eval
            case routes.disclosure:
                return DisclosureTab.CusipGlobalServices
            default:
                return ''
        }
    };

    const isActiveTab = (tab: string) => currentActiveTab() === tab;

    return (
        <div className="container">
            <div className="container-flex container-sidebar">
                <div className="sidebar">
                    <div className="sidebar-title-type01">
                        <h2>
                            <IconSVG name="user-agreement" width={16} height={16} /> Disclaimers
                        </h2>
                    </div>
                    <ul className="sidebar-nav-type01">
                        <li
                            className={classNames({
                                'flex-none': true,
                                active: isActiveTab(DisclaimersTabs.platform)
                            })}
                            onClick={() => history.push(routes.disclaimersPlatform)}
                        >
                            {DisclaimersTabs.platform}
                        </li>
                        <li
                            className={classNames({
                                'flex-none': true,
                                active: isActiveTab(DisclaimersTabs.portfolio)
                            })}
                            onClick={() => history.push(routes.disclaimersPortfolio)}
                        >
                            {DisclaimersTabs.portfolio}
                        </li>
                        <li
                            className={classNames({
                                'flex-none': true,
                                active: isActiveTab(DisclaimersTabs.eval)
                            })}
                            onClick={() => history.push(routes.disclaimersEval)}
                        >
                            {DisclaimersTabs.eval}
                        </li>
                    </ul>
                    <div className="sidebar-title-type01">
                        <h2>
                            <IconSVG name="user-agreement" width={16} height={16} /> Disclosure
                        </h2>
                    </div>
                    <ul className="sidebar-nav-type01">
                        <li
                            className={classNames('flex-none', {
                                active: isActiveTab(DisclosureTab.CusipGlobalServices)
                            })}
                            onClick={() => history.push(routes.disclosure)}
                        >
                            CUSIP Global Services Contract Terms
                        </li>
                    </ul>
                </div>
                <div className="container-flex content-part-sidebar">
                    <div className="container-agreements">
                        <div className="content-agreements">
                            <Switch>
                                <Route
                                    exact={true}
                                    path={routes.disclaimers}
                                    render={() => <Redirect to={routes.disclaimersPlatform} />}
                                />
                                <Route exact={true} path={routes.disclaimersPlatform} component={PlatformDisclaimer} />
                                <Route exact={true} path={routes.disclaimersPortfolio} component={PortfolioDisclaimer} />
                                <Route exact={true} path={routes.disclaimersEval} component={EvalDisclaimer} />
                                <Route exact={true} path={routes.disclosure} component={Disclosure} />
                                <Redirect to={routes.notFound} />
                            </Switch>
                        </div>
                        <LearnMore />
                    </div>
                </div>
            </div>
        </div>
    );
};
