import { useState } from "react";
import { useDispatch } from "react-redux";
import { saveAs } from 'file-saver';
import { RequestState } from "../../../../constants/request-state";
import { errorActions } from "../../../../actions/error.actions";
import { accountService } from "../../../../services/account.service";

export function useCusipTermsSignCallback() {
    const dispatch = useDispatch();
    const [requestState, setRequestState] = useState(RequestState.none);

    const signCusipTermsCallback = async () => {
        try {
            setRequestState(RequestState.request);
            await accountService.signCusipTerms();
            setRequestState(RequestState.success);
        } catch(e) {
            setRequestState(RequestState.failure);
            dispatch(errorActions.unexpectedError(e));
        }
    }

    return { signRequestState: requestState, signCusipTermsCallback };
}

export function useDownloadCusipTermsCallback() {
    const dispatch = useDispatch();
    const [requestState, setRequestState] = useState(RequestState.none);

    const downloadCusipTermsCallback = async () => {
        try {
            setRequestState(RequestState.request);
            const file: { name: string, blob: Blob } = await accountService.downloadCusipTermsFile();
            saveAs(file.blob, file.name);
            setRequestState(RequestState.success);
        } catch(e) {
            setRequestState(RequestState.failure);
            dispatch(errorActions.unexpectedError(e));
        }
    }

    return { downloadRequestState: requestState, downloadCusipTermsCallback };
}