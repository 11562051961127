import { useEffect, useState } from "react";
import { Checkbox, Popup, PopupBody, PopupFooter } from "../../../controls";
import { DisclosureContent } from "./DisclosureContent";
import IconSVG from "../../../../styles/svg-icons";
import { user } from "../../../../user/user";
import { useCusipTermsSignCallback, useDownloadCusipTermsCallback } from "./hooks";
import { isRequestSuccess, isRequesting } from "../../../../utils/request-state.utils";

const isShownStorageKey = "isCusipTermsShown";
const autoOpenTimeoutMinutes = 3;

export function DisclosurePopup() {
    const [visible, setVisible] = useState(false);
    const [agreeCheckBoxChecked, setAgreeCheckBoxChecked] = useState(false);
    const { signRequestState, signCusipTermsCallback } = useCusipTermsSignCallback();
    const { downloadRequestState, downloadCusipTermsCallback } = useDownloadCusipTermsCallback();
    const isShown = !!sessionStorage.getItem(isShownStorageKey);

    useEffect(() => {
        // Auto-open popup if not signed
        if (!visible && !isShown && !user.current()?.cusipTermsSigned) {
            try {
                sessionStorage.setItem(isShownStorageKey, "true");
            } catch (e) {
                console.log('Local storage is unavailable');
            }
            setTimeout(() => {
                if (user.isAuthenticated()) {
                    setVisible(true);
                }
            }, autoOpenTimeoutMinutes * 60 * 1000);
        }
    }, [visible, isShown]);

    useEffect(() => {
        // Sign request success
        if (isRequestSuccess(signRequestState) && visible) {
            setVisible(false);
            user.setCusipTermsSigned();
        }
    }, [visible, signRequestState])

    if (!visible) return null;

    const handleAgreeClick = () => signCusipTermsCallback();
    const handleDownloadClick = () => downloadCusipTermsCallback();
    const handleClose = () => {
        if (!isRequesting(signRequestState)) {
            setVisible(false);
        }
    }

    return (
        <Popup
            renderInBody
            title="CUSIP Global Services Contract Terms"
            modalClass="modal-user-agreement"
            onClose={handleClose}
        >
            <PopupBody>
                <div className="disclaimer">
                    <DisclosureContent />
                    <div className="row">
                        <button
                            className="btn-link"
                            disabled={isRequesting(downloadRequestState)}
                            onClick={handleDownloadClick}
                        >
                            <IconSVG name="downloadTemplate" width={16} height={16} /> Download Disclaimer
                        </button>
                    </div>
                    <div className="row">
                        <Checkbox
                            label="I agree to CUSIP Global Services Contract Terms disclosure"
                            checked={agreeCheckBoxChecked}
                            onChange={() => setAgreeCheckBoxChecked(!agreeCheckBoxChecked)}
                        />
                    </div>
                </div>
            </PopupBody>
            <PopupFooter>
                <button
                    className="btn btn-ghost"
                    disabled={isRequesting(signRequestState)}
                    onClick={handleClose}
                >
                    Cancel
                </button>
                <button
                    className="btn btn-main"
                    onClick={handleAgreeClick}
                    disabled={!agreeCheckBoxChecked || isRequesting(signRequestState)}
                >
                    Agree
                </button>
            </PopupFooter>
        </Popup>
    )
}