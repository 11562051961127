import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, ValidationMessage } from '../forms';
import { accountActions } from '../../actions';

class ChangePhoneNumber extends Component {
    state = {
        phone: this.props.phone || '',
        isValid: true,
    }

    componentDidMount = () => this.props.dispatch(accountActions.changePhoneNumberReset());

    render = () => {
        const { error, onCancel } = this.props;
        const errorMessage = error ? error.errorMessage : null;

        return (
            <div className="edit-form-wrapper">
                <Form onSubmit={this.handleSubmit}>
                    <label className="form-label" htmlFor="phoneNumber">Change Phone Number:</label>
                    <div className="form-control-wrapper">
                        <input
                            className="form-control"
                            autoFocus
                            id="phoneNumber"
                            type="tel"
                            name="phone"
                            value={this.state.phone}
                            maxLength="20"
                            placeholder="Enter Phone"
                            onChange={this.handleChange}
                        />
                        <ValidationMessage
                            for="phone"
                            customMessage={errorMessage}
                            onCustomValidate={() => !(this.props.error && this.props.error.errorMessage)}/>
                        <ValidationMessage
                            for="phone"
                            trigger={this.state.isValid}
                            requiredMessage="Phone number is required"
                        />
                    </div>
                    <div>
                        <button className="btn btn-main" type="submit" disabled={!this.canSave()}>Save</button>
                        <button className="btn btn-ghost" type="button" onClick={onCancel.bind(this)}>Cancel</button>
                    </div>
                </Form>
            </div>
        );
    }

    handleChange = (e) => {
        if (this.props.error) {
            this.props.error.errorMessage = null;
        }

        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit = isValid => {
        this.setState({ isValid });

        if (isValid) {
            this.props.dispatch(accountActions.changePhoneNumber(this.state.phone.trim()));
        }
    }

    canSave = () => {
        const { phone = '' } = this.state;
        const original = this.props.phone || '';
        return phone !== original;
    }
}

const mapStateToProps = ({ profile }) => ({
    phone: profile.profile.phone,
    error: profile.error
});

const connectedChangePhoneNumber = connect(mapStateToProps)(ChangePhoneNumber);
export { connectedChangePhoneNumber as ChangePhoneNumber };
