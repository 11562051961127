export function DisclosureContent() {
    return (
        <div>
            <p>
                Copyright © 2021, American Bankers Association CUSIP Database provided by S&P Global
                Market Intelligence LLC. All rights reserved.
            </p>
            <p>
                The Trust website client (the “User”) agrees and acknowledges that the CUSIP Database and
                the information contained therein is and shall remain valuable intellectual property owned by, or
                licensed to, CUSIP Global Services ("CGS") and the American Bankers Association ("ABA"),
                and that no proprietary rights are being transferred to User in such materials or in any of the
                information contained therein. Any use by the User outside of the clearing and settlement of
                transactions requires a license from CGS, along with an associated fee based on usage. User
                agrees that misappropriation or misuse of such materials will cause serious damage to CGS
                and ABA and that in such event money damages may not constitute sufficient compensation to
                CGS and ABA; consequently, User agrees that in the event of any misappropriation or misuse,
                CGS and ABA shall have the right to obtain injunctive relief in addition to any other legal or
                financial remedies to which CGS and ABA may be entitled.
            </p>
            <p>
                User agrees that User shall not publish or distribute in any medium the CUSIP Database or any
                information contained therein or summaries or subsets thereof to any person or entity except in
                connection with the normal clearing and settlement of security transactions. User further agrees
                that the use of CUSIP numbers and descriptions is not intended to create or maintain, and does not
                serve the purpose of the creation or maintenance of, a master file or database of CUSIP descriptions
                or numbers for itself or any third party recipient of such service and is not intended to create and
                does not serve in any way as a substitute for the CUSIP MASTER TAPE, PRINT, DB, INTERNET, ELECTRONIC,
                CD-ROM Services and/or any other future services developed by the CGS
            </p>
            <p>

                NEITHER CGS, ABA NOR ANY OF THEIR AFFILIATES MAKE ANY WARRANTIES, EXPRESS OR IMPLIED,
                AS TO THE ACCURACY, ADEQUACY OR COMPLETENESS OF ANY OF THE INFORMATION CONTAINED IN THE CUSIP
                DATABASE. ALL SUCH MATERIALS ARE PROVIDED TO THE USER ON AN "AS IS" BASIS, WITHOUT ANY WARRANTIES
                AS TO MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE OR USE NOR WITH RESPECT TO THE RESULTS
                WHICH MAY BE OBTAINED FROM THE USE OF SUCH MATERIALS. NEITHER CGS, ABA NOR THEIR AFFILIATES SHALL
                HAVE ANY RESPONSIBILITY OR LIABILITY FOR ANY ERRORS OR OMISSIONS NOR SHALL THEY BE LIABLE FOR
                ANY DAMAGES, WHETHER DIRECT OR INDIRECT, SPECIAL OR CONSEQUENTIAL, EVEN IF THEY HAVE BEEN ADVISED
                OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT SHALL THE LIABILITY OF CGS, ABA, OR ANY OF THEIR
                AFFILIATES PURSUANT TO ANY CAUSE OF ACTION, WHETHER IN CONTRACT, TORT, OR OTHERWISE, EXCEED THE FEE
                PAID BY THE USER FOR ACCESS TO SUCH MATERIALS IN THE MONTH IN WHICH SUCH CAUSE OF ACTION IS ALLEGED
                TO HAVE ARISEN. FURTHERMORE, CGS AND ABA SHALL HAVE NO RESPONSIBILITY OR LIABILITY FOR DELAYS OR FAILURES
                DUE TO CIRCUMSTANCES BEYOND THEIR CONTROL.
            </p>
            <p>
                User agrees that the foregoing terms and conditions shall survive any termination of its right
                of access to the materials identified above.
            </p>
        </div>
    );
}