import * as React from 'react';
import { ReactNode } from 'react';
import classNames from 'classnames';

interface Props {
    title: string,
    text?: string,
    selected?: boolean,
    enabled?: boolean,
    onClick: () => void,
    children?: ReactNode
}

export function VerifyIdentityMethod({
    title,
    text,
    selected = false,
    enabled = true,
    onClick,
    children
}: Props) {
    return (
        <div className={classNames('control-radio-square', { 'disabled': !enabled }, { selected, enabled })}>
            <input
                type="radio"
                name="companyType"
                id={title}
                onChange={enabled ? onClick : () => true}
                disabled={!enabled}
                checked={selected}
            />
            <label className="control-label" htmlFor={title}>
                {title}<br />
                {!!text && <span>{text}</span>}
            </label>
            {children}
        </div>
    );
}
