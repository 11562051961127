import * as React from 'react';
import { DirectBiddingDisclaimerContext } from '../direct-bidding-agreement-disclaimer/DirectBiddingDisclaimerContext';
import { BlockedFeatureContext } from './BlockedFeatureContext';

interface Props {
    className?: string
    text?: string
    disabled?: boolean,
    children?: React.ReactNode,
    onClick: (e: React.MouseEvent) => void
    [propName: string]: any
}

export function FeatureButton({ className = '', text = '', disabled = false, children = null, onClick, ...rest }: Props) {
    const isFeatureBlocked = React.useContext(BlockedFeatureContext);
    const isDirectBiddingDisclaimerBlocked = React.useContext(DirectBiddingDisclaimerContext);

    return (
        <button
            className={className}
            disabled={disabled || isFeatureBlocked}
            onClick={isFeatureBlocked || isDirectBiddingDisclaimerBlocked ? undefined : onClick}
            {...rest}
        >
            {children} {text}
        </button>
    );
}
