export function EvalDisclaimer() {
    return (
        <div className="tab-pane container-flex">
            <div className="sub-title">
                <h2>EVAL and K-Talk</h2>
            </div>
            <div className="content-section container-flex">
                <p>
                    The Information is being provided to you solely at your request and for your informational purposes only. While the Information is based upon information derived from sources believed to be reliable, KopenTech has not independently verified such Information and is not responsible for its contents.
                </p>
                <p>
                    No representation is made as to the accuracy or completeness of the Information or any promise or representation as to the future performance of any securities, loans or other instruments referenced herein. Any projections, market outlooks or estimates in this document are forward-looking statements and are based upon certain assumptions. Other events which were not considered may occur and may significantly affect the returns or performance. Any projections, outlooks or assumptions should not be construed to be indicative of the actual events which will occur.
                </p>
                <p>
                    You are responsible for your own independent verification of the Information and should consult with your own advisors with respect to the Information before deciding the uses to which the Information may be put. Neither the Information nor the assumptions reflected herein should be construed to be, or constitute, an offer to sell or buy or a solicitation of an offer to sell or buy any securities mentioned herein.
                </p>
                <p>
                    KopenTech uses market data from secondary and primary markets to arrive at valuations. Many securities do not actively trade and some CLO-related CUSIPs are placed in buy-and-hold accounts and have never traded in secondary markets. KopenTech uses a multi-model approach to value a full universe of securities, including non-traded securities. Some models are based on machine learning techniques, for which some prices cannot be traced to a specific input. Valuation prices are extrapolated from other trades in the market which have varied degrees of similarity in structure, collateral quality, and documentations. Many price estimates may rely on as few as a single data point. Price outputs rely heavily on models and model parameters and may be subject to errors and inaccuracies.
                </p>
                <p>
                    KopenTech is not acting as your advisor or agent. Prior to making any investment decision or entering into any transaction, you should determine, without reliance upon KopenTech or any of its affiliates, the economic risks and merits, as well as the legal, tax and accounting characterizations and consequences of any investment or transaction, and independently determine that you are able to assume these risks. In this regard, by acceptance of these materials, you acknowledge that you have been advised that (a) KopenTech is not in the business of providing legal, tax or accounting advice, (b) you understand that there may be legal, tax or accounting risks associated with any investment or transaction, (c) you should receive legal, tax and accounting advice from advisors with appropriate expertise to assess relevant risks.
                </p>
                <p>
                    KopenTech is affiliated with KopenTech Capital Markets, a member of FINRA.
                </p>
            </div>
        </div>
    )
};

