
import { Link, useLocation, useParams } from 'react-router-dom';
import { InputField } from '../../forms';
import { constants, routes } from '../../../constants';
import '../../../styles/main.scss';
import { RequestState } from '../../../constants/request-state';
import { Preloader } from '../../common';
import { Form, Formik } from 'formik';
import { yup } from '../../../validation/yup';
import { ArrangersClientSignupRequest } from '../../../types/account/ArrangersClientSignupRequest';
import { useArrangerClientSave } from './useArrangerClientSave';
import { useAssetManagers } from '../../../effects/useAssetManagers';
import { SelectSearchField } from '../../forms/SelectSearchField';

const otherCompany = {
    Id: 'other',
    Name: 'Other (not in the list)'
};

const validationSchema = yup.object().shape({
    firstName: yup.string().trim().required().matches(constants.name),
    lastName: yup.string().trim().required().matches(constants.name),
    email: yup.string().trim().required().email().typeError('Email is required'),
    phone: yup.string().trim().required().matches(constants.nonWhitespace).max(constants.phoneMaxLength),
    title: yup.string(),
    companyId: yup.mixed().required().typeError('Company is required'),
    companyName: yup.string().trim().when('companyId', {
        is: otherCompany.Id,
        then: () => yup.string().required()
    })
});

export const ArrangerClientSignup = () => {
    const { token } = useParams<{ token: string }>();
    const { saveArrangerClientRequestState, onSave } = useArrangerClientSave();
    const { assetManagers, assetManagersRequestState } = useAssetManagers();
    const isAssetManagersLoaded = assetManagersRequestState === RequestState.success;
    const isLoading = saveArrangerClientRequestState === RequestState.request;
    const locationState = useLocation<{ from?: string }>().state;
    // TODO: Remove when dummy company will be removed from BE
    const filteredAssetManagers = assetManagers.filter(c => c.Name !== otherCompany.Name);

    const handleSubmit = (values: ArrangersClientSignupRequest) => {
        onSave({
            ...values,
            token,
            companyId: values.companyId === otherCompany.Id ? null : values.companyId,
            companyName: values.companyId === otherCompany.Id ? values.companyName : null
        });
    }

    const initialFormValues: ArrangersClientSignupRequest = {
        firstName: '',
        lastName: '',
        email: '',
        companyId: null,
        phone: '',
        title: '',
        companyName: '',
        token,
    };

    const checkSubmitDisabled = (values: ArrangersClientSignupRequest, submitCount: number, isValid: boolean) => {
        const companyNotSelected = values.companyId === null || (values.companyId === otherCompany.Id && !values.companyName);
        const someRequiredFieldEmpty = !values.firstName || !values.lastName || !values.phone || companyNotSelected || !values.email;
        return someRequiredFieldEmpty || !(!submitCount || isValid) || isLoading;
    }

    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
        >
            {({ values, isValid, submitCount }) => (
                <div className="container sign-up">
                    <Preloader inProgress={isLoading}>
                        <Form>
                            <div className="sign-up-cnt">
                                <h2>Sign Up</h2>
                                <div className="form-row form-row-inline">
                                    <div className="form-item">
                                        <InputField
                                            label="First Name"
                                            markRequired={true}
                                            autoFocus
                                            className="form-control"
                                            name="firstName"
                                            maxLength={64}
                                            placeholder="First Name"
                                        />
                                    </div>
                                    <div className="form-item">
                                        <InputField
                                            className="form-control"
                                            markRequired={true}
                                            label="Last Name"
                                            name="lastName"
                                            maxLength={64}
                                            placeholder="Last Name"
                                        />
                                    </div>
                                </div>

                                <div className="form-row">
                                    <InputField
                                        label="Email"
                                        name="email"
                                        markRequired={true}
                                        className="form-control"
                                        placeholder="Email"
                                        maxLength={64}
                                    />
                                </div>

                                <div className="form-row">
                                    <InputField
                                        label="Phone Number"
                                        type="tel"
                                        name="phone"
                                        className="form-control"
                                        markRequired={true}
                                        placeholder="Phone Number"
                                        maxLength={20}
                                    />
                                </div>

                                <div className="form-row">
                                    <InputField
                                        className="form-control"
                                        markRequired={false}
                                        label="Title"
                                        name="title"
                                        maxLength={256}
                                        placeholder="Title"
                                    />
                                </div>

                                <div className="form-row">
                                    <SelectSearchField
                                        className="select-search-field"
                                        label="Company"
                                        name="companyId"
                                        markRequired={true}
                                        placeholder="Company"
                                        disabled={!isAssetManagersLoaded}
                                        options={[...filteredAssetManagers, otherCompany].map(company => ({
                                            value: company.Id,
                                            text: company.Name,
                                            selected: company.Id === values.companyId,
                                            freezed: company.Name === otherCompany.Name
                                        }))}
                                    />
                                </div>

                                {values.companyId === otherCompany.Id && <div className="form-row">
                                    <InputField
                                        className="form-control"
                                        markRequired={true}
                                        label="Company Name"
                                        name="companyName"
                                        maxLength={64}
                                        placeholder="Company Name"
                                    />
                                </div>}

                                <div className="form-row">
                                    <button
                                        className="btn btn-main btn-margin"
                                        type="submit"
                                        disabled={checkSubmitDisabled(values, submitCount, isValid)}
                                    >
                                        Sign Up
                                    </button>
                                    <div className="divider"><span>Have an account?</span></div>
                                    <Link to={{ pathname: routes.login, state: { from: locationState?.from } }} className="btn btn-ghost btn-full-width">sign in</Link>
                                    <p className="text-sm text-warm-grey">
                                        By clicking Sign Up, you agree to <Link to={routes.termsAndConditions}>Terms &amp; Conditions</Link> and <Link to={routes.privacyPolicy}>Privacy Policy</Link>.
                                    </p>
                                </div>
                            </div>
                        </Form>
                    </Preloader>
                </div>)}
        </Formik>
    );
}

