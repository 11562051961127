import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { notificationActions } from '../../../actions';
import { RequestState } from '../../../constants/request-state';
import { ArrangersClientSignupRequest } from '../../../types/account/ArrangersClientSignupRequest';
import { accountService } from '../../../services';
import { routes } from '../../../constants';
import { useHistory } from 'react-router';
import { formatUtils } from '../../../utils';

export const useArrangerClientSave = () => {
    const { replace } = useHistory();
    const dispatch = useDispatch();
    const [saveArrangerClientRequestState, setArrangerClientRequestState] = useState(RequestState.none);

    const onSave = async (arrangerClient: ArrangersClientSignupRequest) => {
        setArrangerClientRequestState(RequestState.request);
        const formattedEmail = formatUtils.maskEmail(arrangerClient.email);
        try {
            await accountService.saveArrangersClientSignupForm(arrangerClient);
            setArrangerClientRequestState(RequestState.success);
            replace({ pathname: routes.arrangersClientSignUpConfirm, state: { email: formattedEmail } });
        } catch (e) {
            dispatch(notificationActions.notificationAddErrorMessage('Sign up form submission failed'));
            setArrangerClientRequestState(RequestState.failure);
        }
    }
    return { saveArrangerClientRequestState, onSave }
}
