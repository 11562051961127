import React from 'react';
import { user } from '../../../user';
import { LearnMore } from '../LearnMore';
import { AuthenticatedDisclaimer } from './AuthenticatedDisclaimer';
import { PlatformDisclaimer } from './tabs/PlatformDisclaimer';

export const Disclaimers = () => {
    if (!user.isAuthenticated()) {
        return (
            <div className="container-agreements">
                <PlatformDisclaimer isAuthenticated={user.isAuthenticated()} />
                <LearnMore />
            </div>
        )
    }
    return <AuthenticatedDisclaimer />
}
