import { appConfig } from "../../app-config";
import { EmailLink } from "../bidding/common/EmailLink";

export function BlockedPlatformAccessText() {
    return (
        <p>
            You have limited access to the KTX ATS Platform. To get access to
            this feature, please email us at{" "}
            <EmailLink email={appConfig.salesEmailSubscription} />{" "}
            or call <span className="text-nowrap">{appConfig.salesPhone}</span>.
        </p>
    );
}
