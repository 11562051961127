import { PortfolioAgreementDisclaimerText } from '../../../portfolio-agreement-disclaimer/PortfolioAgreementDisclaimerText';

export function PortfolioDisclaimer() {
    return (
        <div className="tab-pane container-flex">
            <div className="sub-title">
                <h2>Portfolio</h2>
            </div>
            <div className="content-section container-flex">
                <PortfolioAgreementDisclaimerText />
            </div>
        </div>
    )
};
