import { useAllToAllAgreementBlocked } from "../../effects/useAllToAllAgreementBlocked";
import { OnHoverTooltip } from "../common/OnHoverTooltip";

interface Props {
    disabled?: boolean;
    children: (blocked: boolean) => React.ReactNode;
}

export function AllToAllArgeementRequiredActionBlocker({ disabled, children }: Props) {
    const blocked = useAllToAllAgreementBlocked();
    
    if (disabled || !blocked) {
        return <>{children(false)}</>;
    }

    return (
        <OnHoverTooltip wrapperClass='limit-blocker all2all-blocker' overlay="Becomes available for BDs approved to All-to-All Trading.">
            <>{children(true)}</>
        </OnHoverTooltip>
    );
}
