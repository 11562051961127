import cn from 'classnames';
import { OnHoverTooltip } from "../common";
import { TooltipPlacement } from "../common/Tooltip";
import { BlockedFeatureContext } from "./BlockedFeatureContext";
import { user } from "../../user";
import { TRenderChildren } from "./ActionBlocker";
import { BlockedPlatformAccessText } from './BlockedPlatformAccessText';

interface Props {
    roles: string[];
    className?: string;
    placement?: string | TooltipPlacement;
    children: React.ReactNode | TRenderChildren;
}

export function RoleActionBlocker({ roles, className, placement, children }: Props) {
    
    const renderChildren = (blocked: boolean) => {
        if (typeof children === 'function') {
            return <>{children(blocked)}</>;
        }
        return <>{children}</>;
    };

    if (user.hasRoles(...roles)) {
        return renderChildren(false);
    }

    return (
        <div className={cn('limit-blocker position-relative', className)}>
            <OnHoverTooltip
                placement={placement}
                overlay={<BlockedPlatformAccessText />}
                overlayClassName="trial-tooltip"
            >
                <div>
                    <BlockedFeatureContext.Provider value={true}>{renderChildren(true)}</BlockedFeatureContext.Provider>
                </div>
            </OnHoverTooltip>
        </div>
    );

}
