import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { accountActions, verifyIdentityActions } from '../../actions';
import { Form, ValidationMessage } from '../forms';
import { routes } from '../../constants';
import '../../styles/main.scss';
import { Preloader } from '../common';
import { classnames } from '../../styles/classnames';
import { user } from '../../user';
import { history } from '../../history';

class Login extends Component {
    _mounted = false;
    _timeout = null;

    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            isValid: true,
            autofillApplied: false
        };
    }

    componentDidMount = () => {
        if (user.isAuthenticated()) {
            history.replace(routes.root);
        } else {
            this.props.dispatch(verifyIdentityActions.reset());
            this.props.dispatch(accountActions.resetStore());
            this._mounted = true;
            this._timeout = setTimeout(() => {
                try {
                    if (this._mounted) {
                        const autofillApplied =
                            this._email &&
                            this._email.matches &&
                            this._email.matches(':-webkit-autofill');
                        this.setState({ autofillApplied });
                    }
                } catch (e) { }
            }, 100);
        }
    }

    componentWillUnmount() {
        this._mounted = false;
        if (this._timeout) {
            clearTimeout(this._timeout);
        }
    }

    render = () => {
        const errorMessage = this.props.error ? this.props.error.errorMessage : null;
        const isLoading = this.props.isLoading;

        const isLoadingClasses = classnames({
            'is-loading': isLoading === true
        });

        return (
            <div className="content-account content-login">
                <Form className={isLoadingClasses} onSubmit={this.handleSubmit}>
                    <div className="title">
                        <span className="loading-text">Proceed</span>
                        <h2 className="title-text">Sign In</h2>
                    </div>

                    <div className="form-row">
                        <label className="form-label" htmlFor="emailUser">Email <span className="text-red">*</span></label>
                        <div className="form-control-wrapper">
                            <input
                                ref={node => this._email = node}
                                type="email"
                                id="emailUser"
                                name="email"
                                className="form-control"
                                autoComplete="username"
                                onChange={this.handleChange}
                                autoFocus
                                required
                            />
                            <ValidationMessage
                                for="email"
                                trigger={this.state.isValid}
                                requiredMessage="Email is required"
                                defaultMessage="Invalid email address"
                            />
                        </div>
                    </div>
                    <div className="form-row">
                        <label className="form-label" htmlFor="passwordUser">Password <span className="text-red">*</span></label>
                        <div className="form-control-wrapper">
                            <input
                                type="password"
                                id="passwordUser"
                                className="form-control"
                                name="password"
                                autoComplete="current-password"
                                onChange={this.handleChange}
                                required
                            />
                            <ValidationMessage
                                for="password"
                                trigger={this.state.isValid}
                                requiredMessage="Password is required"
                            />
                        </div>
                    </div>
                    <div className="form-row">
                        <Link className="link-account link-forgot" to={routes.forgotPassword}>Forgot password?</Link>
                    </div>
                    <div className={errorMessage ? 'status-message error' : 'status-message display-none'}>
                        <i className="icon icon-error" />
                        <span className="status-message-cnt">{errorMessage}</span>
                    </div>
                    <div className="form-row margin-b-0">
                        <div className="text-sm text-warm-grey">
                            By clicking Sign In, you agree to <Link to={routes.termsAndConditions}>Terms &amp; Conditions</Link> and <Link to={routes.privacyPolicy}>Privacy Policy</Link>.
                        </div>
                    </div>
                    <Preloader inProgress={isLoading}>
                        <div className="authorization-actions">
                            <button
                                type="submit"
                                disabled={!this.state.autofillApplied && (this.state.email === '' || this.state.password === '')}
                                className="btn btn-main btn-full-width"
                            >
                                Sign In
                            </button>
                            <div className="divider"><span>or</span></div>
                            <Link to={routes.signUp} className="btn btn-ghost btn-full-width">sign up</Link>
                        </div>
                    </Preloader>
                </Form>
            </div>
        );
    }

    handleChange = (e) => {
        if (this.props.error) {
            this.props.error.errorMessage = null;
        }
        const { name, value } = e.target;
        this.setState({ [name]: value, autofillApplied: false });
    }

    handleSubmit = isValid => {
        this.setState({ isValid });

        if (isValid) {
            const { email, password } = this.state;
            const { dispatch, location } = this.props;
            const { from = '/' } = location.state || {};

            dispatch(accountActions.login(email, password, from));
        }
    }
}

const mapStateToProps = ({ authentication }) => ({
    error: authentication.error,
    isLoading: authentication.isLoading,
});

const connectedLogin = connect(mapStateToProps)(Login);
export { connectedLogin as Login };
