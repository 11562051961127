import { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { accountActions } from '../../actions';
import { Form, ValidationMessage } from '../forms';
import { routes } from '../../constants';
import ImageSVG from '../../styles/svg';
import { SupportInformation } from '../../components/common/SupportInformation'
import { user } from '../../user';
import { history } from '../../history';

class ResetPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            isValid: true,
            isDisabledBtn: false
        };
    }

    componentDidMount = () => {
        const { location } = this.props;
        const isFinishedState = location && location.state && location.state.isFinished;

        if (user.isAuthenticated()) {
            history.replace(routes.root);
        } else if (this.isFinished() && !isFinishedState) {
            history.replace(routes.forgotPassword);
        }
    }

    componentWillUnmount = () => this.props.dispatch(accountActions.resetPasswordReset());

    componentDidUpdate = (prevProps) => {
        if (this.props.errorMessage && this.props.errorMessage === prevProps.errorMessage) {
            this.setState({ isDisabledBtn: false });
        }
    };

    isFinished = () => {
        const { location } = this.props;
        return location && location.pathname === routes.forgotPasswordFinished;
    }

    render = () => {
        if (this.isFinished()) {
            return (
                <div className="container-main">
                    <div className="content-account content-account-send content-login">
                        <h1>Finished!</h1>
                        <div className="image">
                            <ImageSVG name="resetPassword" width={326} height={208} />
                        </div>
                        <p>
                            Please check your email.<br/>
                            Haven't received an email? <Link to={routes.forgotPassword}>Try again</Link> or <Link to={routes.login}>sign&nbsp;in</Link>
                        </p>
                        <SupportInformation />
                    </div>
                </div>
            );
        }

        const { errorMessage } = this.props;

        return (
            <div className="content-account content-login">
                <Form onSubmit={this.handleSubmit}>
                    <div className="title">
                        <h2 className="title-text">Forgot your password?</h2>
                    </div>

                    <div className={errorMessage ? 'status-message error' : 'status-message display-none'}>
                        <i className="icon icon-error" />
                        <span className="status-message-cnt">{errorMessage}</span>
                    </div>

                    <div className="form-row">
                        <label className="form-label" htmlFor="emailUser">Email <span className="text-red">*</span></label>
                        <div className="form-control-wrapper">
                            <input
                                type="email"
                                id="emailUser"
                                className="form-control"
                                name="email"
                                autoComplete="email"
                                onChange={this.handleChange}
                                autoFocus
                                required
                            />
                            <ValidationMessage
                                for="email"
                                trigger={this.state.isValid}
                                requiredMessage="Email is required"
                                defaultMessage="Invalid email address"
                            />
                        </div>
                    </div>
                    <div className="authorization-actions">
                        <button className="btn btn-main btn-full-width" type="submit" disabled={this.state.isDisabledBtn}>Reset password</button>
                    </div>
                    <Link className="go-back" to={routes.login}>Back to Sign In</Link>
                </Form>
                <SupportInformation />
            </div>
        );
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit = isValid => {
        this.setState({ isValid });

        if (isValid) {
            const { email } = this.state;
            const { dispatch } = this.props;
            
            dispatch(accountActions.resetPassword(email));
            this.setState({ isDisabledBtn: true });
        }
    }
}

const mapStateToProps = ({ authentication }) => {
    return {
        success: authentication.success,
        errorMessage: authentication.errorMessage
    };
};

const connectedResetPassword = connect(mapStateToProps)(ResetPassword);
export { connectedResetPassword as ResetPassword }; 
