import * as React from 'react';
import { SettlementAgreementStatus } from '../../types/bid-as-dealer/SettlementAgreementStatus';
import { SettlementAgentAgreement } from '../../types/bid-as-dealer/SettlementAgentAgreement';
import { OnHoverTooltip } from '../common';

interface MissingAgreementTooltipProps {
    agreement?: SettlementAgentAgreement;
    children: React.ReactNode;
}

export const NOT_REQUESTED_DIRECT_BIDDING = 'You are not requested the DirectBidding™ yet.'
export const WAIT_FOR_RESPONSE_DIRECT_BIDDING = 'You are already requested the DirectBidding™. Please wait for your response';
export const DIRECT_BIDDING_WAS_SUSPENDED = 'Your request for the DirectBidding™ was suspended by the Settlement Agent';

export function MissingAgreementTooltip({ agreement, children }: MissingAgreementTooltipProps) {

    const getMissingAgreementTooltip = () => {
        switch (true) {
            case !agreement:
                return NOT_REQUESTED_DIRECT_BIDDING;
            case agreement?.agreementStatus === SettlementAgreementStatus.pending:
                return WAIT_FOR_RESPONSE_DIRECT_BIDDING;
            case agreement?.agreementStatus === SettlementAgreementStatus.rejected:
                return DIRECT_BIDDING_WAS_SUSPENDED;
            default:
                return null;
        }
    }

    const tooltip = getMissingAgreementTooltip();

    if (tooltip) {
        return <OnHoverTooltip overlay={tooltip}>{children}</OnHoverTooltip>
    }

    return <>{children}</>;
}
