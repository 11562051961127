import React from 'react';
import ImageSVG from '../../styles/svg';

export function SignupConfirm() {
    return (
        <div className="container sign-up container-overflow">
            <div className="sign-up-thanks">
                <h1>Thank you!</h1>
                <ImageSVG name="sendFormState" width="293" height="184" />
                <p>We have received your request.</p>
                <p>A representative will be contacting you soon.</p>
            </div>
        </div>
    );
}
