export function PlatformDisclaimer({ isAuthenticated = true }: { isAuthenticated: boolean }) {

    const disclaimerText = () => {
        return <>
            <p>
                These Legal Disclaimers apply to the use of certain websites which are owned and/or operated
                by or on behalf of KopenTech LLC (each, the “Site”, and collectively, the “Sites”), through our
                authorized third parties, or by any of the other means described herein.
            </p>
            <p>
                When we refer herein to “we,” “us,” or “our,” we mean KopenTech LLC or the specific division,
                subsidiary, or affiliate that operates this Site, provides its content, or processes information
                received through it, each as appropriate and applicable. When we refer to “you” or “your,” we
                mean the person accessing this Site. If the person accessing this Site acts on behalf of, or for the
                purposes of, another person, including a business or other organization, “you” or “your” also
                means that other person, including a business organization.
            </p>
            <p>
                Your use of this site and the products and services offered or referenced on this site, including
                those of third parties, are at your own risk. All information and content and all products and
                services offered through this site, including content, products and services provided by third
                party advertisers and sponsors on this site, are provided “as is” without any express or implied
                warranty or representation of any kind including, without limitation, any implied warranties of
                merchantability, fitness for a particular purchase, title or non-infringement.
            </p>
            <p>
                No information contained in this website may be construed as an express or an implied promise,
                guarantee or implication by, of, or from KopenTech LLC that you will profit or that losses can or
                will be limited in any manner whatsoever. Past performance is not necessarily indicative of
                future results. Although care has been taken to ensure the accuracy, completeness and reliability
                of the information contained herein, KopenTech LLC makes no warranty, express or implied, or
                assume any legal liability or responsibility for the accuracy, completeness, reliability or
                usefulness of any information, product, service or process disclosed.
            </p>
            <p>
                The Sites are not intended to provide tax, financial or investment advice. Although the Sites may
                include provision of data, information, or content relating to investment strategies and/or
                opportunities to buy and/or sell securities, you should not construe any such data, information, or
                content as tax, financial or investment advice. KopenTech LLC does not provide tax or financial
                planning services. Nothing contained on the Sites may be construed as tax, financial or
                investment advice. KopenTech LLC does not warrant the accuracy, truthfulness or completeness
                of any information about securities or companies viewable on the Sites. KopenTech LLC
                expresses no opinion as to the suitability of any transaction for any person contemplating a
                transaction to be facilitated through the Sites. Any person contemplating a transaction to be
                facilitated through the Sites should make his, her or its own independent investigation of the
                suitability of any proposed transaction based on the facts and circumstances of such person's
                financial situation. KopenTech LLC strongly recommends that Users consult with providers of
                legal, tax, financial planning and/or investment advisory services prior to initiating a transaction
                on the Sites.
            </p>
            <p>
                In no event will KopenTech LLC, its subsidiaries, affiliated companies, suppliers or third parties
                (“Suppliers”) be liable to any party for any direct, indirect, special or other consequential damages arising out of or related to your use of this site or any of the products or services offered
                through this site, or any other hyperlinked or affiliated site including, without limitation, any lost
                revenues, lost profits, loss of prospective economic advantage, business interruption, loss of
                programs or other data on your handling system or otherwise arising out of your use, misuse of
                or inability to use this site or any hyperlinked or affiliated site, or the information, content or
                software contained herein or therein, even if KopenTech LLC, its subsidiaries, affiliated
                companies or joint partners are advised of the possibility of such damages or for any claim by a
                third party. Your sole and exclusive remedy against KopenTech LLC is to discontinue use of this
                site and any hyperlinked or affiliated sites and to discontinue the use of products and services
                offered or referenced on this site and any hyperlinked or affiliated sites.
            </p>
            <p>
                Without limiting the above disclaimers, KopenTech LLC, its subsidiaries, affiliated companies
                and Suppliers: (1) make no warranties or representations whatsoever concerning this Site or any
                other Internet Site, the access to, or the availability or use of, this Site or any other Internet
                website, the information and content from whatever source posted on or referred to in this Site or
                any other Internet website or the accuracy, completeness or timeliness of such information or
                content; (2) do not warrant or represent that your access to, or use of, this Site or any other
                Internet website will be uninterrupted or free of errors or omissions, that defects will be
                corrected, or that this Site or any other Internet website is, or the information or content from
                whatever source available for use or downloading are free of computer viruses, worms, Trojan
                horses or other harmful components; (3) do not represent or warrant that any services, products
                or content listed on, or accessed through, this Site will be available for purchase or use or not
                withdrawn at any time and makes no representation or warranty of any kind whatsoever
                concerning such products, services or content; and (4) do not represent or warrant the accuracy,
                functionality, specifications or any other aspect of items from whatever source posted or
                accessed through this Site. Please note that some jurisdictions may not allow the exclusion of
                implied warranties, so some of the above exclusions may not apply to you.
            </p>
            <p>
                This Site may use hyperlinks as a convenience to you so that reference material, KopenTech
                LLC related subjects and other pertinent material is easily accessible. Linked and referenced
                websites may not be operated, controlled or maintained by KopenTech LLC. Hyperlinks and
                references to other websites, including any KopenTech LLC affiliated entity, do not constitute
                sponsorship, endorsement or approval by KopenTech LLC of the information, content, policies
                or practices of such linked or referenced websites. KopenTech LLC, its subsidiaries, affiliated
                companies and Suppliers do not accept any responsibilities for any information or content,
                availability, policies, practices or any use of such websites. You access, browse and use such
                websites at your own risk. KopenTech LLC has no obligation to update any information or
                content on this Site. Accordingly, KopenTech LLC, its subsidiaries, affiliated companies and
                Suppliers assume no responsibility regarding the accuracy of the information or content provided
                on the Site. Any use of the information or content provided on this Site is done so at your own
                risk.
            </p>
            <p>
                No recommendations or advice provided. KopenTech LLC provides services to self-directed
                investors. KopenTech LLC does not provide recommendations or investment advice of any kind
                through any of the sites.
            </p>
            <p>
                KopenTech LLC and KopenTech Capital Markets LLC disclaim any responsibility for the
                accuracy, reliability, or completeness of ratings displayed on the platform. Users are advised to
                conduct their own independent due diligence to verify the accuracy and appropriateness of the
                ratings before making any decisions.
            </p>
            <p>
                Although information and content relating to investments and financial instruments may be
                available through this Site, you should not construe such information and content as financial,
                legal, investment, or financial advice. You alone bear the responsibility of evaluating any
                information or content available on this Site. In exchange for using such information or content,
                you hereby agree to release from any claim and to not hold KopenTech LLC, its subsidiaries,
                affiliated companies and its Suppliers liable for any possible claim for damages arising from any
                decision that you may make based on information or content available on the Site.
            </p>
        </>
    }

    if (isAuthenticated) {
        return <div className="tab-pane container-flex">
            <div className="sub-title">
                <h2>Platform</h2>
            </div>
            <div className="content-section container-flex">
                {disclaimerText()}
            </div>
        </div>

    }
    return (
        <div className="content-agreements">
            <h2>Disclaimers</h2>
            {disclaimerText()}
        </div>
    )
}
