import React, { useState, useEffect } from 'react';
import { Popup, PopupBody, PopupFooter } from "../controls";
import { EmailChipList } from "../common/EmailChipList";
import { FlexRow, Preloader } from "../common";
import { useRequestInviteBuysideClients } from "../../effects";
import { useDispatch } from "react-redux";
import { notificationActions } from "../../actions";
import { StatusMessageSection } from '../status-message/StatusMessageSection';
import { StatusMessageSectionType } from '../../types/state/NotificationState';
import { validateEmail } from '../../utils/validate-email.utils'

export function InviteClientsPopup({ onClose }) {
    const dispatch = useDispatch();
    const [emailList, setEmailList] = useState([]);
    const [sending, setSending] = useState(false);
    const [sent, setSent] = useState(false);
    const [error, setError] = useState(null);

    useRequestInviteBuysideClients(emailList, sending, setSending, setSent, setError);

    useEffect(() => {
        if (sent) {
            dispatch(notificationActions.notificationAddInfoMessage('Invitation sent'));
            onClose && onClose();
        } else if (!sent && error) {
            dispatch(notificationActions.notificationAddErrorMessage('Could not send invitation'));
        }
    }, [sent, error, dispatch, onClose]);

    const handleClose = () => {
        if (!sending) {
            onClose && onClose();
        }
    }

    const handleSend = () => {
        setSending(true);
    }

    const handleChangeEmailList = list => setEmailList(list);
    const isSomeEmailInvalid = emailList.some(email => !validateEmail(email));

    return (
        <Popup
            title="Invite to KTX ATS Platform"
            modalClass="modal-clients-invite"
            onClose={handleClose}
        >
            <PopupBody>
                <StatusMessageSection type={StatusMessageSectionType.Info}>
                    Invite colleagues and clients to join the KTX ATS platform. The notification will be sent to the list of emails below.
                </StatusMessageSection>
                <EmailChipList
                    allowInvalid
                    withClearAll
                    disabled={sending}
                    onChange={handleChangeEmailList}
                    className="email-chips-list-with-clear"
                    placeholder="Enter recipients or Ctrl+C / Ctrl+V emails from Excel" />
            </PopupBody>
            <PopupFooter>
                <FlexRow>
                    {sending && <Preloader inProgress={sending} fullScreen={false} small={true} />}
                    <button
                        type="button"
                        className="btn btn-ghost"
                        onClick={handleClose}
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        className="btn btn-main"
                        onClick={handleSend}
                        disabled={!emailList.length || sending || isSomeEmailInvalid}
                    >
                        Send invitation
                    </button>
                </FlexRow>
            </PopupFooter>
        </Popup>
    )
}
