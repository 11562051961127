import * as React from 'react';
import classNames from 'classnames';
import { constants } from '../../../constants/constants';
import { VerifyIdentityMethodType } from '../../../types/account/VerifyIdentityMethodType';
import { Timer } from '../../common/Timer';
import { FormError } from '../../controls';

interface Props{
    initialDate: Date
    selectedMethod: VerifyIdentityMethodType
    code: string
    phoneMask?: string
    emailMask: string
    isCodeExpired: boolean
    isCodeInvalid: boolean
    resendCodeVisible?: boolean
    disabled: boolean,
    changeVerificationMethodEnabled?: boolean
    onResend: () => void
    onVerificationMethodChange?: () => void
    onCodeChange: (code: string, canSubmit: boolean) => void,
    onExpiredChange: (expired: boolean) => void
}

export function IdentityCodeSubmitContent({
    initialDate,
    selectedMethod,
    phoneMask,
    emailMask,
    code,
    isCodeExpired,
    isCodeInvalid,
    changeVerificationMethodEnabled = false,
    resendCodeVisible = true,
    disabled = false,
    onResend,
    onVerificationMethodChange,
    onCodeChange,
    onExpiredChange}: Props
) {
    const [resendCodeTimeoutEllapsed, setResendCodeTimeoutEllapsed] = React.useState(false);
    const text = selectedMethod === VerifyIdentityMethodType.email
        ? <>We have sent an email containing a one-time code to the following email: <strong>{emailMask}</strong></>
        : <>We have texted your phone <strong>{phoneMask}</strong></>;

    React.useEffect(() => {
        setResendCodeTimeoutEllapsed(false);
    }, [initialDate]);

    const handleCodeChange = (e: any) => {
        const { value } = e.target;
        const canSubmit = /^\d{6}$/.test(value);
        onCodeChange(value, canSubmit);
    }

    const handleCodeReSend = () => {
        if (onResend) {
            onResend();
        }
    }

    const handleExpired = (expired: boolean) => {
        if (onExpiredChange) {
            onExpiredChange(expired);
        }
    }

    const renderResendButton = () =>
        <span className={classNames({ 'btn-link': true, 'disabled': disabled })} onClick={handleCodeReSend} >
            Re-send code
        </span>

    const renderMethodChangeButton = () =>
        <span className={classNames({ 'btn-link': true, 'disabled': disabled })}  onClick={onVerificationMethodChange} >
            use another verification option
        </span>

    return (
        <>
            <p>
                {text}. Please enter the code within {constants.verifyIdentityCodeLifetimeMinutes} minutes to sign in.
            </p>
            <div className="timer-area">
                <Timer
                    start={initialDate}
                    durationSeconds={constants.verifyIdentityCodeLifetimeMinutes * 60}
                    onEllapsed={() => handleExpired(true)}
                />
                {
                    resendCodeVisible &&
                    isCodeExpired &&
                    <button className="btn btn-main flex-item-right" onClick={handleCodeReSend} disabled={disabled}>re-send code</button>
                }
            </div>
            {
                !isCodeExpired &&
                <>
                    <div className="form-row">
                        <label className="form-label">Code <span className="text-red">*</span></label>
                        <input
                            className={classNames({ 'form-control': true, 'is-invalid': isCodeInvalid })}
                            type="text"
                            value={code}
                            maxLength={constants.verifyIdentityCodeLength}
                            onChange={handleCodeChange}
                            disabled={disabled}
                            placeholder="Enter 6-digit code"
                        />
                        {isCodeInvalid && <FormError message="Verification code is wrong" />}
                    </div>
                    {
                        resendCodeTimeoutEllapsed &&
                        <p className="trouble">
                            Having trouble? {renderResendButton()} {changeVerificationMethodEnabled && <>or {renderMethodChangeButton()}</>}
                        </p>
                    }
                    {
                        !resendCodeTimeoutEllapsed &&
                        <div className="re-send-section">
                            Having trouble? Re-send code will be available in&nbsp;
                            <Timer
                                start={initialDate}
                                durationSeconds={constants.reSendIdentityCodeTimeoutSeconds}
                                onEllapsed={() => setResendCodeTimeoutEllapsed(true)}
                                customFormat={duration => `${duration.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })} sec`}
                            />
                        </div>
                    }
                </>
            }
        </>
    );
}
