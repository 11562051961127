import { SubscriptionFeature } from "../../types/billing/SubscriptionFeature";
import { BlockedFeatureContent, SubscribeLink } from "./BlockedFeatureText";
import { RequiredFeature } from "./RequiredFeature";

interface Props {
    feature?: SubscriptionFeature;
    features?: SubscriptionFeature[];
    requireAllFeatures?: boolean;
    chartName: string;
    blockedClassName: string;
    children: React.ReactNode;
}

export function ChartRequiredFeature({ feature, features, requireAllFeatures, chartName, blockedClassName, children }: Props) {
    const renderBlockedContent = () =>
        <BlockedFeatureContent
            withBackground
            className={blockedClassName}
            text={<><SubscribeLink /> to see the <b>{chartName}</b> graph.</>}
        />

    return (
        <RequiredFeature
            feature={feature}
            features={features}
            requireAllFeatures={requireAllFeatures}
            renderBlockedContent={renderBlockedContent}
        >
            {children}
        </RequiredFeature>
    );
}