import * as React from 'react';
import { useSelector } from "react-redux";
import { AppState } from '../../../types/state/AppState';
import { VerifyIdentityState } from '../../../types/state/VerifyIdentityState';
import { verifyIdentityActions } from '../../../actions/verify.identity.actions';
import { IdentityCodeSubmitContent } from './IdentityCodeSubmitContent';
import { useAppDispatch } from '../../../effects/useAppDispatch';

export function IdentityCodeSubmit() {
    const dispatch = useAppDispatch();
    const [code, setCode] = React.useState('');
    const [canSubmit, setCanSubmit] = React.useState(false);
    const [codeExpired, setCodeExpired] = React.useState(false);
    const [codeSendAttempt, setCodeSendAttempt] = React.useState(0);

    const {
        isVerificationRequired,
        isVerificationCodeRequesting,
        isVerificationCodeConfirming,
        isVerificationCodeInvalid,
        selectedMethod,
        verificationCodeSentDate,
        emailCode,
        phoneCode
    } = useSelector<AppState, VerifyIdentityState>(state => state.verifyIdentity);

    React.useEffect(() => {
        setCanSubmit(false);
        setCodeExpired(false);
        setCode('');
        setCodeSendAttempt(0);
    }, [verificationCodeSentDate]);

    if (!isVerificationRequired || !verificationCodeSentDate) {
        return null;
    }

    const handleCodeReSend = () => dispatch(verifyIdentityActions.requestVerificationCode());

    const handleCodeChange = (code: string, canSubmit: boolean) => {
        setCode(code);
        setCanSubmit(canSubmit);
        dispatch(verifyIdentityActions.invalidVerificationCode(false));
    }

    const handleSubmitClick = () => {
        const attempt = codeSendAttempt + 1;
        setCodeSendAttempt(attempt);
        dispatch(verifyIdentityActions.submitVerificationCode(code, attempt));
    }

    const isLoading = isVerificationCodeRequesting || isVerificationCodeConfirming;

    return (
        <div className="tfa-container">
            <div className="tfa-verify">
                <h2>Verify your identity</h2>
                <IdentityCodeSubmitContent
                    initialDate={verificationCodeSentDate}
                    selectedMethod={selectedMethod}
                    code={code}
                    phoneMask={phoneCode}
                    emailMask={emailCode}
                    isCodeExpired={codeExpired}
                    isCodeInvalid={isVerificationCodeInvalid}
                    changeVerificationMethodEnabled={!!phoneCode}
                    disabled={isLoading}
                    onResend={handleCodeReSend}
                    onVerificationMethodChange={() => dispatch(verifyIdentityActions.verificationMethodReset())}
                    onCodeChange={handleCodeChange}
                    onExpiredChange={(expired: boolean) => setCodeExpired(expired)}
                />
                {
                    !codeExpired &&
                    <button
                        className="btn btn-main btn-full-width"
                        disabled={!canSubmit || isLoading}
                        onClick={handleSubmitClick}
                    >
                        verify
                    </button>
                }
            </div>
        </div>
    );
}
